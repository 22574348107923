html,
body {
  overflow-x: hidden !important;
  font-family: 'Nunito', sans-serif !important; }

@media screen and (min-width: 1921px) {
  html,
  body {
    font-size: 20px; } }

@media screen and (max-width: 1920px) and (min-width: 1681px) {
  html,
  body {
    font-size: 20px; } }

@media screen and (max-width: 1680px) and (min-width: 1600px) {
  html,
  body {
    font-size: 19px; } }

@media screen and (max-width: 1599px) and (min-width: 1360px) {
  html,
  body {
    font-size: 17px; } }

@media screen and (max-width: 1359px) and (min-width: 1024px) {
  html,
  body {
    font-size: 15px; } }

@media screen and (max-width: 1023px) and (min-width: 768px) {
  html,
  body {
    font-size: 15px; } }

@media screen and (max-width: 767px) and (min-width: 320px) {
  html,
  body {
    font-size: 14px; } }

.btn,
button,
Input,
input,
select {
  outline: none; }
  .btn:focus,
  button:focus,
  Input:focus,
  input:focus,
  select:focus {
    box-shadow: none;
    -webkit-box-shadow: none;
    outline: none; }

.map {
  height: 30rem; }

.required {
  border: 1px solid #FF5050 !important; }

.breadcrumbs {
  display: flex;
  justify-content: flex-end; }
  .breadcrumbs p {
    font-size: 1rem !important;
    line-height: 1.35rem;
    color: #FEBA11;
    font-weight: 700;
    margin-bottom: 0 !important; }
    .breadcrumbs p a {
      color: #222222;
      font-weight: 400; }
      .breadcrumbs p a::after {
        margin-right: .2rem;
        margin-left: .2rem;
        margin-bottom: .1rem;
        content: '';
        display: inline-block;
        width: .3rem;
        height: .3rem;
        -moz-border-radius: 100%;
        -webkit-border-radius: 100%;
        border-radius: 100%;
        background-color: #222222; }
      .breadcrumbs p a:hover {
        text-decoration: none; }

.vrAnim .gun {
  position: absolute;
  right: 3.4rem;
  animation: shake2 4s;
  animation-iteration-count: infinite; }

.vrAnim .laser {
  position: absolute;
  left: 2.5rem;
  animation: shake2 4s;
  animation-iteration-count: infinite; }

.vrAnim .leftVr {
  position: absolute;
  left: 2.5rem;
  animation: shake2 4s;
  animation-iteration-count: infinite; }

.vrAnim .rightVr {
  position: absolute;
  right: 2.5rem;
  animation: shake2 4s;
  animation-iteration-count: infinite; }

.animateButton {
  background: #FFFFFF;
  border-radius: 1.25rem;
  border: 0 !important;
  outline: none !important;
  overflow: hidden;
  position: relative;
  width: 9rem;
  height: 3.5rem;
  line-height: 0.8rem;
  -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16); }
  .animateButton span {
    font-size: 1.1rem;
    line-height: 1.5rem;
    position: relative;
    color: #FFFFFF;
    z-index: 2;
    letter-spacing: 1px;
    font-weight: 600; }
  .animateButton:focus, .animateButton:hover {
    -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16); }
    .animateButton:focus span, .animateButton:hover span {
      color: #FFFFFF; }

.animacija {
  opacity: 1;
  border-radius: 1.25rem;
  background: red;
  animation-name: buttonAnimacija;
  animation-duration: 0.7s;
  width: 800px;
  height: 800px;
  margin-left: -420px;
  margin-top: -450px;
  z-index: 4; }

@keyframes buttonAnimacija {
  from {
    width: 0px;
    height: 0px;
    border-radius: 100%;
    margin-left: 0;
    margin-top: 0; }
  to {
    width: 800px;
    height: 800px;
    margin-left: -420px;
    margin-top: -450px;
    border-radius: 100%; } }

.spacerNavbar {
  height: 8rem; }

.Navigacija {
  padding-top: 0.75rem;
  padding-bottom: 1rem;
  background: white;
  -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  height: 8rem;
  z-index: 22222; }
  .Navigacija p {
    margin: 0; }
  .Navigacija .navigacijaSpacer .container {
    position: relative; }
    .Navigacija .navigacijaSpacer .container:first-child::after {
      content: '';
      background: #707070;
      height: 1px;
      bottom: -0.7rem;
      width: 100%;
      position: absolute; }
  .Navigacija .headerLogo {
    width: 5.9rem;
    height: 5.5rem;
    position: absolute;
    top: 1.25rem;
    left: 3rem; }
  .Navigacija .headerInfo p {
    font-size: 1.1rem;
    line-height: 1.5rem;
    font-weight: 400;
    color: #222222; }
    .Navigacija .headerInfo p svg {
      margin-right: 0.5rem;
      margin-top: -4px; }
  .Navigacija .headerSocial {
    position: absolute;
    right: 3rem;
    display: flex;
    top: 0.75rem; }
    .Navigacija .headerSocial p {
      line-height: 1.5rem; }
    .Navigacija .headerSocial svg {
      margin-right: 0.8rem;
      height: 1.4rem;
      width: 1.4rem; }
    .Navigacija .headerSocial > div {
      position: relative; }
      .Navigacija .headerSocial > div:first-child::after {
        content: '';
        background: #707070;
        height: 1px;
        bottom: -0.7rem;
        width: calc(100% + 400px);
        position: absolute;
        left: -400px; }
  .Navigacija .btn-menu {
    display: none; }
  .Navigacija .navbar {
    justify-content: space-between;
    padding-top: 1.8rem;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0; }
    .Navigacija .navbar .nav-item {
      list-style: none;
      padding-right: 3.3rem; }
      .Navigacija .navbar .nav-item .nav-link {
        padding: 0;
        font-size: 1rem;
        line-height: 1.35rem;
        font-weight: 600;
        color: #222222;
        height: 2.8rem;
        display: flex;
        align-items: center; }
    .Navigacija .navbar .active {
      background-image: url("././images/svg/star.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain; }
  .Navigacija .menuWrapper {
    position: relative; }
    .Navigacija .menuWrapper .container::after {
      display: none; }
    .Navigacija .menuWrapper .headerButtons {
      position: absolute;
      top: 2rem;
      right: 3rem; }
      .Navigacija .menuWrapper .headerButtons .animateButton {
        background: #7A1C7A;
        border-radius: 1.25rem;
        border: 0 !important;
        outline: none !important;
        overflow: hidden;
        position: relative;
        width: 8rem;
        height: 2.5rem;
        line-height: 0.8rem;
        box-shadow: none; }
        .Navigacija .menuWrapper .headerButtons .animateButton:first-child {
          margin-right: 1.5rem; }
        .Navigacija .menuWrapper .headerButtons .animateButton span {
          font-size: 1.1rem;
          line-height: 1.5rem;
          position: relative;
          color: #FFFFFF;
          z-index: 2;
          letter-spacing: 0;
          font-weight: 600; }
        .Navigacija .menuWrapper .headerButtons .animateButton:focus span, .Navigacija .menuWrapper .headerButtons .animateButton:hover span {
          color: #FFFFFF; }
      .Navigacija .menuWrapper .headerButtons .animacija {
        opacity: 1;
        border-radius: 1.25rem;
        background: #FEBA11;
        animation-name: buttonAnimacija;
        animation-duration: 0.7s;
        width: 800px;
        height: 800px;
        margin-left: -420px;
        margin-top: -450px;
        z-index: 4; }

@keyframes buttonAnimacija {
  from {
    width: 0px;
    height: 0px;
    border-radius: 100%;
    margin-left: 0;
    margin-top: 0; }
  to {
    width: 800px;
    height: 800px;
    margin-left: -420px;
    margin-top: -450px;
    border-radius: 100%; } }

@media screen and (max-width: 991px) and (min-width: 300px) {
  .Navigacija .btn-menu {
    display: block;
    background: none;
    outline: none;
    border: none;
    transform: scale(2);
    margin-right: 15px;
    margin-top: 30px;
    float: right; }
  .Navigacija .headerSocial {
    display: none; }
  .Navigacija .navbar-collapse {
    position: fixed;
    left: 0;
    top: 107px;
    text-align: center;
    width: 100%;
    padding: 0;
    z-index: 13;
    background-color: #7A1C7A; }
    .Navigacija .navbar-collapse .nav-item {
      list-style: none; }
      .Navigacija .navbar-collapse .nav-item .nav-link {
        color: white;
        padding: 0.75rem 1rem; } }

@media screen and (max-width: 991px) and (min-width: 768px) {
  .Navigacija .headerButtons {
    top: 1.5rem !important;
    right: 6.5rem !important;
    width: 100%;
    justify-content: flex-end;
    display: flex;
    left: unset; }
    .Navigacija .headerButtons .animateButton {
      width: 6rem !important; }
      .Navigacija .headerButtons .animateButton span {
        font-size: 0.9rem !important; } }

@media screen and (max-width: 1480px) and (min-width: 1200px) {
  .Navigacija .navigacijaSpacer {
    margin-left: 10rem; } }

@media screen and (max-width: 1359px) and (min-width: 991px) {
  .Navigacija .headerSocial > div::after {
    display: none; } }

@media screen and (max-width: 1200px) and (min-width: 0) {
  .Navigacija .navigacijaSpacer .container:first-child::after {
    display: none; } }

@media screen and (min-width: 1921px) {
  .Navigacija .headerSocial > div::after {
    display: none; } }

@media screen and (min-width: 1880px) {
  .Navigacija .navbar .nav-item {
    padding-right: 0 !important; }
  .Navigacija .navbar .navbar-collapse {
    justify-content: space-between; } }

@media screen and (max-width: 1480px) and (min-width: 1360px) {
  .Navigacija .navbar .nav-item {
    padding-right: 2.5rem !important; } }

@media screen and (max-width: 1260px) and (min-width: 992px) {
  .Navigacija .headerButtons .animateButton {
    width: 6rem !important; }
    .Navigacija .headerButtons .animateButton span {
      font-size: 0.9rem !important; }
  .Navigacija .navbar .nav-item {
    padding-right: 1.4rem !important; } }

@media screen and (max-width: 991px) and (min-width: 768px) {
  .Navigacija .navigacijaSpacer .headerInfo:nth-child(2) p {
    text-align: right; }
  .Navigacija .headerLogo {
    left: 40px; }
  .Navigacija .btn-menu {
    margin-right: 40px; } }

@media screen and (max-width: 767px) and (min-width: 0) {
  .Navigacija .headerLogo {
    left: 15px; }
  .Navigacija .headerInfo {
    display: none; }
  .Navigacija .headerButtons {
    top: 1.5rem !important;
    right: unset !important;
    width: 100%;
    justify-content: center;
    display: flex;
    left: 2rem; }
    .Navigacija .headerButtons .animateButton {
      width: 6rem !important; }
      .Navigacija .headerButtons .animateButton span {
        font-size: 0.9rem !important; } }

.footer {
  color: #FFFFFF;
  background-color: #FEBA11;
  padding-top: 2.5rem; }
  .footer p {
    margin-top: 1rem;
    font-size: .9rem;
    line-height: 1.3rem;
    margin-bottom: 2.25rem; }
  .footer .socialNetwork {
    display: flex; }
    .footer .socialNetwork svg {
      margin-right: 1.25rem; }
  .footer .footerMenu h2 {
    font-size: 1rem;
    line-height: 1.3rem;
    font-weight: 700;
    margin-bottom: 1.5rem; }
  .footer .footerMenu ul {
    padding-left: 0; }
    .footer .footerMenu ul li {
      list-style: none;
      align-items: baseline;
      padding-bottom: .9rem; }
      .footer .footerMenu ul li a {
        color: #FFFFFF; }
        .footer .footerMenu ul li a:hover {
          text-decoration: none; }
      .footer .footerMenu ul li::before {
        margin-right: .5rem;
        margin-bottom: .1rem;
        content: '';
        display: inline-block;
        width: .5rem;
        height: .5rem;
        -moz-border-radius: 100%;
        -webkit-border-radius: 100%;
        border-radius: 100%;
        background-color: #7A1C7A; }
  .footer .sec {
    margin-top: 3rem; }
  .footer .contact h2 {
    font-size: 1rem;
    line-height: 1.3rem;
    font-weight: 700;
    margin-bottom: 1.5rem; }
  .footer .contact ul {
    list-style: none;
    padding-left: 0; }
    .footer .contact ul li {
      font-size: 1rem;
      line-height: 1.5rem;
      margin-bottom: .9rem; }
      .footer .contact ul li svg {
        width: 1rem;
        margin-right: .5rem; }
  .footer .copyRight {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between; }
    .footer .copyRight p {
      font-size: .8rem;
      line-height: 1rem; }
      .footer .copyRight p span a {
        color: #FFFFFF;
        font-weight: 700; }
        .footer .copyRight p span a:hover {
          text-decoration: none; }

@media screen and (max-width: 767px) and (min-width: 300px) {
  .footer .logo svg {
    width: 100%; }
  .footer .socialNetwork {
    margin-bottom: 2rem; }
  .footer .copyRight {
    display: block; } }

.galleryPictures {
  margin-top: 3rem; }
  .galleryPictures img {
    border-radius: 1rem;
    margin-bottom: 1.5rem;
    cursor: pointer;
    transition: transform .3s; }
    .galleryPictures img:hover {
      transform: scale(1.1); }

.lightbox {
  overflow-y: hidden !important;
  overflow-x: hidden !important;
  background-color: #333333;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1213113212; }
  .lightbox .container {
    background: #333333; }
  .lightbox img {
    height: 100vh;
    object-fit: contain;
    margin-top: 40px; }
  .lightbox .fa-times {
    color: white;
    font-size: 42px !important;
    position: absolute;
    top: 60px;
    right: 70px;
    z-index: 2; }
    .lightbox .fa-times:hover {
      cursor: pointer; }
  .lightbox .fa-angle-left {
    color: white;
    font-size: 50px;
    position: fixed;
    top: 48%;
    left: 40px; }
    .lightbox .fa-angle-left:hover {
      cursor: pointer; }
  .lightbox .fa-angle-right {
    color: white;
    font-size: 50px;
    position: fixed;
    top: 48%;
    right: 40px; }
    .lightbox .fa-angle-right:hover {
      cursor: pointer; }

.contactForm {
  padding-top: 4rem;
  padding-bottom: 6rem;
  background: #F5F5F5; }
  .contactForm h1 {
    font-size: 3.2rem;
    font-weight: 700;
    line-height: 3.75rem;
    color: #7A1C7A;
    margin-bottom: 1rem; }
  .contactForm p {
    font-size: 1.1rem;
    line-height: 1.65rem;
    font-weight: 400;
    margin-bottom: 1rem; }
  .contactForm h1 {
    text-align: center;
    margin-bottom: 0; }
  .contactForm p {
    text-align: center;
    margin-bottom: 2.75rem; }
  .contactForm .form-group {
    margin-bottom: 1.5rem !important; }
    .contactForm .form-group .form-control {
      border-radius: 1.25rem;
      border: 1px solid #C4C4C4;
      padding: 0.65rem 1.5rem;
      height: 2.5rem; }
      .contactForm .form-group .form-control::placeholder {
        color: #C4C4C4; }
  .contactForm textarea {
    height: 12.5rem !important;
    border-radius: 1.5rem;
    padding: 1.25rem 1.5rem;
    border: 1px solid #C4C4C4; }
    .contactForm textarea::placeholder {
      color: #C4C4C4; }
  .contactForm .contactButton {
    margin-top: 2rem;
    width: 100%;
    display: flex;
    justify-content: center; }
    .contactForm .contactButton .animateButton {
      background: #7A1C7A;
      width: 11rem;
      height: 3.5rem;
      border-radius: 1.75rem; }
      .contactForm .contactButton .animateButton span {
        letter-spacing: 0;
        font-size: 1rem; }
    .contactForm .contactButton .animacija {
      background: #FEBA11 !important; }

@media screen and (max-width: 767px) and (min-width: 0) {
  .contactForm {
    padding: 3rem 0; }
    .contactForm h1 {
      margin-bottom: 1rem; } }

.homeGallery {
  background-color: #7A1C7A;
  padding: 4.5rem 0; }
  .homeGallery h1 {
    font-size: 3.2rem;
    font-weight: 700;
    line-height: 3.75rem;
    color: #7A1C7A;
    margin-bottom: 1rem; }
  .homeGallery p {
    font-size: 1.1rem;
    line-height: 1.65rem;
    font-weight: 400;
    margin-bottom: 1rem; }
  .homeGallery .title {
    text-align: center;
    width: 100%; }
    .homeGallery .title h1 {
      color: white;
      margin-bottom: 0; }
    .homeGallery .title p {
      color: white;
      margin-bottom: 0; }
  .homeGallery .homeButtonWrapper {
    display: flex;
    justify-content: center;
    margin-top: 0.5rem; }
    .homeGallery .homeButtonWrapper .animateButton {
      background: #FEBA11;
      width: 15rem;
      height: 3.5rem;
      border-radius: 1.75rem; }
      .homeGallery .homeButtonWrapper .animateButton span {
        font-size: 1rem;
        line-height: 1.2rem;
        letter-spacing: 0; }
    .homeGallery .homeButtonWrapper .animacija {
      background: #B3DE5B; }

.vr {
  background-color: unset !important;
  background-image: url("././images//vr/bgc.png"); }

@media screen and (max-width: 767px) and (min-width: 0) {
  .homeGallery {
    padding: 3rem 0; } }

.about {
  position: relative;
  margin-top: 1.75rem; }
  .about .animatedBalloonRight .starRight {
    top: 15rem; }
  .about .animatedBalloonRight .balloonRight {
    bottom: unset;
    top: 43rem; }
  .about .content {
    margin-top: 1.65rem; }
    .about .content h1 {
      font-size: 3.2rem;
      font-weight: 700;
      line-height: 3.75rem;
      color: #7A1C7A;
      margin-bottom: 1rem; }
    .about .content p {
      font-size: 1.1rem;
      line-height: 1.65rem;
      font-weight: 400;
      margin-bottom: 1rem; }
    .about .content p {
      margin-top: 1.35rem;
      margin-bottom: 1.65rem; }
    .about .content h1 {
      font-weight: 700 !important; }
    .about .content .imgWrapp img {
      width: 100%;
      height: 30rem;
      object-fit: cover; }
  .about .happening .animatedBalloonRight .balloonRight {
    z-index: 44454;
    top: unset;
    bottom: 5rem; }

.kontakt {
  margin-top: 1.75rem;
  position: relative; }
  .kontakt .animatedBalloon .star {
    top: 14.9rem; }
  .kontakt .animatedBalloonRight .starRight {
    top: 10rem; }
  .kontakt .contactRow {
    margin-bottom: 2.5rem; }
  .kontakt .content {
    margin-top: 1.65rem; }
    .kontakt .content h1 {
      font-size: 3.2rem;
      font-weight: 700;
      line-height: 3.75rem;
      color: #7A1C7A;
      margin-bottom: 1rem; }
    .kontakt .content p {
      font-size: 1.1rem;
      line-height: 1.65rem;
      font-weight: 400;
      margin-bottom: 1rem; }
    .kontakt .content h1 {
      margin-bottom: 0.5rem;
      font-weight: 700; }
    .kontakt .content p {
      margin-bottom: 3.5rem; }
  .kontakt .box {
    -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    padding: 0.9rem 1.4rem;
    border-radius: 1.5rem;
    margin-bottom: 2rem; }
    .kontakt .box p {
      font-size: 1.1rem;
      line-height: 1.65rem;
      font-weight: 400;
      margin-bottom: 1rem; }
    .kontakt .box .text {
      margin-left: 0.5rem; }
    .kontakt .box svg {
      height: 2.1rem;
      width: 1.5rem;
      margin-top: 0.5rem; }
    .kontakt .box span {
      font-weight: 700;
      line-height: 1.5rem; }
    .kontakt .box p {
      margin-bottom: 0; }

@media screen and (max-width: 767px) and (min-width: 0) {
  .kontakt .box .text {
    margin-left: 0; }
    .kontakt .box .text p {
      font-size: 0.9rem; } }

@media screen and (max-width: 991px) and (min-width: 768px) {
  .kontakt .box .text {
    margin-left: 0; }
    .kontakt .box .text p {
      font-size: 0.9rem; } }

.birthday {
  position: relative;
  margin-top: 1.75rem;
  padding-bottom: 6rem; }
  .birthday .animatedBalloonRight .starRight {
    top: 15rem; }
  .birthday .content h1 {
    font-size: 3.2rem;
    font-weight: 700;
    line-height: 3.75rem;
    color: #7A1C7A;
    margin-bottom: 1rem; }
  .birthday .content p {
    font-size: 1.1rem;
    line-height: 1.65rem;
    font-weight: 400;
    margin-bottom: 1rem; }
  .birthday .content h1 {
    margin-top: 1.65rem; }
  .birthday .content p {
    margin-bottom: 3.5rem; }
  .birthday .box {
    cursor: pointer;
    transition: transform .3s;
    height: 16.5rem;
    margin-bottom: 1.5rem;
    -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    border-radius: 20px;
    position: relative;
    width: 100%;
    overflow: hidden; }
    .birthday .box img {
      width: 100%;
      object-fit: contain; }
    .birthday .box .content {
      padding-top: .75rem;
      padding-bottom: .75rem;
      position: absolute;
      bottom: 0;
      text-align: center;
      background-color: #FFFFFF;
      width: 100%; }
      .birthday .box .content h2 {
        font-size: 1.1rem;
        line-height: 1.65rem;
        color: #7A1C7A;
        font-weight: 700;
        margin-bottom: .75rem; }
      .birthday .box .content .animateButton {
        background-color: #7A1C7A;
        border-radius: 1.75rem;
        width: 7.5rem;
        height: 2.25rem; }
        .birthday .box .content .animateButton span {
          font-size: 1rem;
          line-height: 1.2rem; }
      .birthday .box .content .animacija {
        background-color: #FEBA11; }
    .birthday .box:hover {
      transform: scale(1.1); }

.birthdayDetail {
  margin-top: 1.75rem; }
  .birthdayDetail .reservationForm .contactForm {
    background: white; }
    .birthdayDetail .reservationForm .contactForm .react-datepicker-wrapper {
      width: 100%; }
      .birthdayDetail .reservationForm .contactForm .react-datepicker-wrapper .react-datepicker__input-container {
        width: 100%; }
      .birthdayDetail .reservationForm .contactForm .react-datepicker-wrapper input {
        border-radius: 1.25rem;
        width: 100%;
        border: 1px solid #C4C4C4;
        padding: 0.65rem 1.5rem;
        height: 2.5rem;
        color: #C4C4C4; }
        .birthdayDetail .reservationForm .contactForm .react-datepicker-wrapper input::placeholder {
          color: #C4C4C4; }
    .birthdayDetail .reservationForm .contactForm .react-time-picker__wrapper {
      width: 100% !important;
      border-radius: 1.25rem;
      width: 100%;
      border: 1px solid #C4C4C4;
      height: 2.5rem; }
    .birthdayDetail .reservationForm .contactForm .react-time-picker {
      width: 100%; }
    .birthdayDetail .reservationForm .contactForm .react-time-picker__inputGroup {
      width: 100%;
      justify-content: center; }
    .birthdayDetail .reservationForm .contactForm .react-time-picker__button {
      right: 1rem;
      position: relative;
      padding: 0; }
      .birthdayDetail .reservationForm .contactForm .react-time-picker__button:focus {
        box-shadow: none;
        outline: none; }
      .birthdayDetail .reservationForm .contactForm .react-time-picker__button svg {
        height: 0.7rem;
        width: 0.7rem; }
  .birthdayDetail p {
    font-size: 1.1rem;
    line-height: 1.65rem;
    font-weight: 400;
    margin-bottom: 1rem; }
  .birthdayDetail .content {
    margin-top: 1.65rem; }
    .birthdayDetail .content h1 {
      font-size: 3.2rem;
      font-weight: 700;
      line-height: 3.75rem;
      color: #7A1C7A;
      margin-bottom: 1rem; }
    .birthdayDetail .content p {
      font-size: 1.1rem;
      line-height: 1.65rem;
      font-weight: 400;
      margin-bottom: 1rem; }
  .birthdayDetail .spacer {
    margin-top: 2rem; }
  .birthdayDetail h1 {
    margin-bottom: 2.4rem !important; }
  .birthdayDetail img {
    width: 100%;
    max-height: 17.5rem; }
  .birthdayDetail h6 {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.65rem;
    padding-top: 0.5rem;
    margin-bottom: 2rem; }
    .birthdayDetail h6 span {
      color: #FEBA11;
      font-size: 1.8rem; }
  .birthdayDetail .newh6 {
    margin-top: 1rem;
    margin-bottom: 0.4rem; }
  .birthdayDetail .newh6-2 {
    margin-bottom: 1.5rem; }
  .birthdayDetail p {
    margin-bottom: 0;
    line-height: 1.7rem; }
  .birthdayDetail .optionButtons {
    margin-bottom: 2.5rem; }
    .birthdayDetail .optionButtons .animateButton {
      background: #FEBA11;
      width: unset;
      height: 3rem;
      letter-spacing: 0 !important;
      padding: 0 1rem; }
      .birthdayDetail .optionButtons .animateButton span {
        font-size: 1.1rem;
        line-height: 2rem; }
      .birthdayDetail .optionButtons .animateButton:nth-child(2) {
        background: #EFEFEF;
        margin-left: 3rem; }
        .birthdayDetail .optionButtons .animateButton:nth-child(2) span {
          color: #222222; }
    .birthdayDetail .optionButtons .animacija {
      background: #7A1C7A; }
  .birthdayDetail .optionFix {
    display: flex;
    margin-bottom: 1.5rem;
    align-items: center; }
    .birthdayDetail .optionFix p {
      font-size: 1.1rem;
      line-height: 1.65rem;
      font-weight: 400;
      margin-bottom: 1rem; }
    .birthdayDetail .optionFix p {
      margin-bottom: 0 !important;
      margin-left: 0.5rem; }
      .birthdayDetail .optionFix p span {
        color: #7A1C7A;
        font-weight: 700 !important; }
  .birthdayDetail .selectOptions {
    margin-bottom: 4rem;
    /* Hide default HTML checkbox */
    /* The slider */
    /* Rounded sliders */ }
    .birthdayDetail .selectOptions form {
      width: 100%; }
    .birthdayDetail .selectOptions p {
      font-size: 1.1rem;
      line-height: 1.65rem;
      font-weight: 400;
      margin-bottom: 1rem; }
    .birthdayDetail .selectOptions .optionWrapper {
      display: flex;
      align-items: center; }
      .birthdayDetail .selectOptions .optionWrapper p {
        margin-bottom: 0;
        margin-left: 2rem; }
        .birthdayDetail .selectOptions .optionWrapper p span {
          color: #7A1C7A;
          font-weight: 700; }
    .birthdayDetail .selectOptions .switch {
      position: relative;
      display: block;
      width: 2.5rem;
      margin-bottom: 0;
      height: 1.25rem; }
    .birthdayDetail .selectOptions .slajder input {
      opacity: 0;
      width: 0;
      height: 0; }
    .birthdayDetail .selectOptions input[type=checkbox],
    .birthdayDetail .selectOptions input[type=radio] {
      width: 100%;
      z-index: 1;
      height: 100%; }
    .birthdayDetail .selectOptions .slajder {
      position: absolute;
      pointer-events: none;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
      border: 1px solid #C2C2C2; }
    .birthdayDetail .selectOptions .slajder:before {
      position: absolute;
      content: "";
      height: 100%;
      width: 1.25rem;
      top: 0;
      left: 0px;
      bottom: 0px;
      background-color: #7A1C7A;
      -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
      -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
      box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
      -webkit-transition: .4s;
      transition: .4s; }
    .birthdayDetail .selectOptions input:checked + .slajder {
      background-color: white; }
    .birthdayDetail .selectOptions input:focus + .slajder {
      box-shadow: 0 0 1px #7A1C7A; }
    .birthdayDetail .selectOptions input:checked + .slajder:before {
      -webkit-transform: translateX(1.25rem);
      -ms-transform: translateX(1.25rem);
      transform: translateX(1.25rem);
      background-color: #FEBA11 !important; }
    .birthdayDetail .selectOptions .slajder.round {
      border-radius: 34px; }
    .birthdayDetail .selectOptions .slajder.round:before {
      border-radius: 50%; }

@media screen and (max-width: 767px) and (min-width: 0) {
  .birthdayDetail img {
    margin-bottom: 1.5rem; }
  .birthdayDetail .optionButtons {
    justify-content: space-between; }
    .birthdayDetail .optionButtons .animateButton {
      width: 48% !important;
      height: unset;
      padding: 0.5rem 1rem; }
      .birthdayDetail .optionButtons .animateButton span {
        line-height: 1.5rem;
        font-size: 0.9rem; }
      .birthdayDetail .optionButtons .animateButton:nth-child(2) {
        margin-left: 0; } }

.detail {
  position: relative;
  margin-top: 1.75rem;
  margin-bottom: 5.5rem; }
  .detail .animatedBalloon .blueBalloon {
    top: 40rem; }
  .detail .animatedBalloonRight .starRight {
    top: 13rem; }
  .detail .contentDetail {
    margin-bottom: 3.6rem; }
    .detail .contentDetail h1 {
      font-size: 3.2rem;
      font-weight: 700;
      line-height: 3.75rem;
      color: #7A1C7A;
      margin-bottom: 1rem; }
    .detail .contentDetail h1 {
      margin-bottom: 0; }
    .detail .contentDetail h6 {
      font-size: 1rem;
      line-height: 1.65rem;
      color: #7A1C7A;
      margin-bottom: 2.4rem; }
    .detail .contentDetail img {
      width: 100%;
      object-fit: contain; }
    .detail .contentDetail p {
      font-size: 1.1rem;
      line-height: 1.65rem;
      font-weight: 400;
      margin-bottom: 1rem; }
    .detail .contentDetail p {
      margin-top: 1.75rem; }

.dVirtual {
  margin-bottom: 7.5rem;
  margin-top: 1.75rem;
  position: relative; }
  .dVirtual .animatedBalloonRight .starRight {
    top: 15rem; }
  .dVirtual .dVirtualContent h1 {
    font-size: 3.2rem;
    font-weight: 700;
    line-height: 3.75rem;
    color: #7A1C7A;
    margin-bottom: 1rem; }
  .dVirtual .dVirtualContent img {
    width: 100%;
    height: 25rem;
    object-fit: contain; }
  .dVirtual .dVirtualContent h1 {
    margin-top: 1.65rem;
    margin-bottom: 2.35rem; }
  .dVirtual .dVirtualContent .text p {
    font-size: 1.1rem;
    line-height: 1.65rem;
    font-weight: 400;
    margin-bottom: 1rem; }
  .dVirtual .dVirtualContent .text p {
    margin-top: 1.75rem; }
  .dVirtual .dVirtualContent .text h2 {
    font-size: 1.8rem;
    line-height: 3.6rem; }
  .dVirtual .dVirtualContent .text .animateButton {
    background-color: #FEBA11;
    width: 14.75rem;
    height: 3rem;
    border-radius: 5rem; }
    .dVirtual .dVirtualContent .text .animateButton span {
      font-size: 1.5rem;
      line-height: 2rem; }
  .dVirtual .dVirtualContent .text .animacija {
    background-color: #7A1C7A; }

.offers {
  position: relative;
  margin-top: 1.75rem;
  margin-bottom: 4.5rem; }
  .offers .animatedBalloon .blueBalloon {
    top: 45rem; }
  .offers .animatedBalloon .doubleStars {
    top: 70rem;
    left: 3rem; }
  .offers .animatedBalloonRight .balloonRight {
    top: unset;
    bottom: unset;
    top: 50%; }
  .offers .animatedBalloonRight .starRight {
    top: unset;
    bottom: unset;
    top: 15rem; }
  .offers .animatedBalloonRight .balonStar {
    position: absolute;
    right: 3rem;
    bottom: -10rem;
    top: unset; }
  .offers .contentOffer h1 {
    font-size: 3.2rem;
    font-weight: 700;
    line-height: 3.75rem;
    color: #7A1C7A;
    margin-bottom: 1rem; }
  .offers .contentOffer h1 {
    margin-top: 1.65rem;
    margin-bottom: 2.35rem !important; }
  .offers .contentOffer .box {
    margin-bottom: 3rem;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
    transition: height 0.5s;
    height: 12.5rem;
    width: 100%;
    -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16); }
    .offers .contentOffer .box img {
      z-index: -1;
      height: 12.5rem;
      width: 100%;
      object-fit: cover; }
    .offers .contentOffer .box .title {
      position: absolute;
      margin-top: -20px;
      z-index: 2;
      height: 2.5rem;
      width: 100%;
      text-align: center;
      bottom: 0;
      background: #FFFFFF;
      transition: height 0.5s;
      -webkit-transition: height 0.5s; }
      .offers .contentOffer .box .title h6 {
        margin-top: .5rem;
        font-size: 1.1rem;
        line-height: 1.65rem;
        font-weight: 700;
        color: #7A1C7A;
        margin-bottom: .25rem; }
      .offers .contentOffer .box .title p {
        font-size: 1.1rem;
        line-height: 1.65rem;
        font-weight: 600;
        margin-bottom: .6rem; }
      .offers .contentOffer .box .title .animateButton {
        width: 7.5rem;
        height: 2.25rem;
        border-radius: 1.75rem;
        background-color: #7A1C7A; }
      .offers .contentOffer .box .title .animacija {
        background-color: #FEBA11; }
    .offers .contentOffer .box:hover {
      height: 18rem; }
      .offers .contentOffer .box:hover .title {
        height: 8rem; }
  .offers .contentOffer .wrapper {
    height: 14rem; }
    .offers .contentOffer .wrapper .title {
      height: 4rem; }
    .offers .contentOffer .wrapper:hover {
      height: 19.65rem; }
      .offers .contentOffer .wrapper:hover .title {
        height: 9.65rem; }
  .offers .contentOffer h3 {
    font-size: 1.8rem;
    line-height: 0.3 0.75rem;
    font-weight: 700;
    margin-bottom: 1.5rem; }

.activityOffer {
  overflow: hidden;
  position: relative;
  background: #EFEFEF;
  padding-top: 5rem;
  padding-bottom: 2rem; }
  .activityOffer h1 {
    font-size: 3.2rem;
    font-weight: 700;
    line-height: 3.75rem;
    color: #7A1C7A;
    margin-bottom: 1rem; }
  .activityOffer p {
    font-size: 1.1rem;
    line-height: 1.65rem;
    font-weight: 400;
    margin-bottom: 1rem; }
  .activityOffer .lines .linesLeft {
    top: -1rem; }
  .activityOffer .lines .rinesRight {
    bottom: -4rem; }
  .activityOffer h1 {
    margin-bottom: 0;
    text-align: center; }
  .activityOffer p {
    text-align: center;
    margin-bottom: 2.5rem; }
  .activityOffer .multipleButtons {
    margin-bottom: 2.5rem; }
    .activityOffer .multipleButtons span {
      font-size: 0.9rem;
      line-height: 1rem;
      letter-spacing: 0;
      display: flex;
      justify-content: center; }
    .activityOffer .multipleButtons .animateButton {
      background: #11157E !important;
      font-size: 0.9rem;
      line-height: 1.65rem;
      -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
      -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
      box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
      border-radius: 1.5rem;
      width: 100%;
      height: 3rem; }
    .activityOffer .multipleButtons [class*=col]:nth-child(2) .animateButton {
      background: #FEBA11 !important; }
    .activityOffer .multipleButtons [class*=col]:nth-child(2) .animacija {
      background: #E92A76 !important; }
    .activityOffer .multipleButtons [class*=col]:nth-child(3) .animateButton {
      background: #B3DE5B !important; }
    .activityOffer .multipleButtons [class*=col]:nth-child(3) .animacija {
      background: #7A1C7A !important; }
    .activityOffer .multipleButtons [class*=col]:nth-child(4) .animateButton {
      background: #49C7EE !important; }
    .activityOffer .multipleButtons [class*=col]:nth-child(4) .animacija {
      background: #E92A76 !important; }
    .activityOffer .multipleButtons [class*=col]:nth-child(5) .animateButton {
      background: #FAD701 !important; }
    .activityOffer .multipleButtons [class*=col]:nth-child(5) .animacija {
      background: #B3DE5B !important; }
    .activityOffer .multipleButtons [class*=col]:nth-child(6) .animateButton {
      background: #7A1C7A !important; }
    .activityOffer .multipleButtons [class*=col]:nth-child(6) .animacija {
      background: #FEBA11 !important; }
  .activityOffer .slick-slider {
    overflow: hidden;
    padding-bottom: 5rem;
    width: 100%; }
    .activityOffer .slick-slider .wrapper {
      display: flex !important;
      flex-wrap: wrap; }
      .activityOffer .slick-slider .wrapper .box {
        margin-bottom: 1.5rem;
        max-height: 250px; }
        .activityOffer .slick-slider .wrapper .box:hover {
          transition: 0.6s ease;
          -webkit-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.3);
          -moz-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.3);
          box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.3);
          transform: scale(1.03);
          border-radius: 1rem;
          cursor: pointer; }
        .activityOffer .slick-slider .wrapper .box img {
          border-top-left-radius: 1rem;
          border-top-right-radius: 1rem;
          height: 10rem;
          width: 100%;
          object-fit: cover; }
        .activityOffer .slick-slider .wrapper .box .textBox {
          background: white;
          border-bottom-left-radius: 1rem;
          border-bottom-right-radius: 1rem;
          height: 2.5rem;
          display: flex;
          justify-content: center;
          align-items: center; }
          .activityOffer .slick-slider .wrapper .box .textBox p {
            margin-bottom: 0;
            font-size: 1.1rem;
            font-weight: 600; }
    .activityOffer .slick-slider .slick-next,
    .activityOffer .slick-slider .slick-prev {
      top: unset;
      bottom: 0;
      height: 3rem;
      width: 3rem;
      z-index: 0;
      border-radius: 100%;
      background: #FEBA11;
      margin-bottom: 0.5rem; }
      .activityOffer .slick-slider .slick-next::before,
      .activityOffer .slick-slider .slick-prev::before {
        display: none; }
    .activityOffer .slick-slider .slick-next {
      right: 15px; }
      .activityOffer .slick-slider .slick-next::after {
        content: "";
        display: block;
        border-left: 4px solid white;
        border-top: 4px solid white;
        width: 0.8rem;
        height: 0.8rem;
        transform: rotate(135deg);
        margin: auto; }
    .activityOffer .slick-slider .slick-prev {
      left: 15px; }
      .activityOffer .slick-slider .slick-prev::after {
        content: "";
        display: block;
        border-left: 4px solid white;
        border-top: 4px solid white;
        width: 0.8rem;
        height: 0.8rem;
        transform: rotate(-45deg);
        margin: auto; }

@media screen and (max-width: 767px) and (min-width: 0) {
  .activityOffer {
    padding: 3rem 0; }
    .activityOffer .animateButton {
      margin-bottom: 2rem; } }

.birthdayHome {
  position: relative;
  padding-top: 4.5rem;
  padding-bottom: 4.6rem;
  overflow: hidden; }
  .birthdayHome .animatedBalloon .balloon {
    left: 5rem; }
  .birthdayHome .animatedBalloon .star {
    left: 1rem; }
  .birthdayHome .animatedBalloonRight .balloonRight {
    bottom: 10rem; }
  .birthdayHome .animatedBalloonRight .starRight {
    margin-top: 2rem; }
  .birthdayHome .content h1 {
    font-size: 3.2rem;
    font-weight: 700;
    line-height: 3.75rem;
    color: #7A1C7A;
    margin-bottom: 1rem; }
  .birthdayHome .content p {
    font-size: 1.1rem;
    line-height: 1.65rem;
    margin-bottom: 1.6rem; }
  .birthdayHome .content .animateButton {
    width: 100%;
    border-radius: 3.65rem;
    background-color: #EFEFEF;
    margin-bottom: 1.5rem;
    -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16); }
    .birthdayHome .content .animateButton span {
      color: #7A1C7A;
      font-size: 2rem; }
    .birthdayHome .content .animateButton:hover span {
      color: #FFFFFF; }
  .birthdayHome .content .animacija {
    background-color: #FEBA11; }
  .birthdayHome .content .wrapperButton {
    margin-top: .5rem; }
    .birthdayHome .content .wrapperButton a .animateButton {
      width: 10rem;
      background-color: #7A1C7A; }
      .birthdayHome .content .wrapperButton a .animateButton span {
        color: #FFFFFF;
        font-size: 1rem; }
  .birthdayHome .wrapper {
    position: relative;
    height: 39.9rem;
    z-index: -1; }
    .birthdayHome .wrapper .boxAnimation {
      -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
      -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
      box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
      position: absolute;
      height: 23rem;
      width: 27.75rem;
      background-color: #FFFFFF;
      z-index: 5555;
      bottom: 0;
      left: -4.75rem;
      border-radius: 1.5rem;
      text-align: center;
      display: flex;
      overflow: hidden; }
      .birthdayHome .wrapper .boxAnimation .stars {
        position: absolute;
        margin: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        transition: bottom ease 0.5s; }
      .birthdayHome .wrapper .boxAnimation .starsAnimation {
        bottom: -2rem; }
      .birthdayHome .wrapper .boxAnimation .starsAnimation2 {
        bottom: -1rem; }
      .birthdayHome .wrapper .boxAnimation .torta {
        transition: transform .6s;
        /* Animation */
        margin: auto;
        margin-bottom: 3.25rem;
        width: 12.5rem;
        height: 12.1rem;
        overflow-y: hidden;
        z-index: 3; }
      .birthdayHome .wrapper .boxAnimation .animation {
        transform: scale(1.3); }
      .birthdayHome .wrapper .boxAnimation .animation35 {
        transform: scale(1.5); }
      .birthdayHome .wrapper .boxAnimation .animatedContent {
        margin: auto;
        position: absolute;
        top: 0;
        bottom: -5rem;
        left: 0;
        right: 0;
        z-index: 2;
        width: 5.8645rem;
        height: 5.894rem;
        transition: transform .6s; }
      .birthdayHome .wrapper .boxAnimation .animated {
        transform: scale(4.6); }

.color1 .animateButton {
  background-color: #FEBA11 !important; }
  .color1 .animateButton span {
    color: #FFFFFF !important; }

@media screen and (max-width: 767px) and (min-width: 300px) {
  .birthdayHome .wrapper .balon {
    display: none; }
  .birthdayHome .wrapper .boxAnimation {
    margin-top: 2rem;
    position: relative;
    width: 100%;
    left: unset; } }

@media screen and (max-width: 1023px) and (min-width: 768px) {
  .birthdayHome .wrapper .boxAnimation {
    left: -3.75rem;
    height: 20rem;
    width: 24.75rem; } }

.slider {
  z-index: -1;
  height: 35rem; }
  .slider .carousel {
    width: 100%;
    position: relative;
    height: 100%; }
    .slider .carousel .carousel-indicators {
      display: none; }
    .slider .carousel .carousel-inner {
      height: 100%; }
      .slider .carousel .carousel-inner .carousel-item {
        width: 100%;
        height: 100%; }
        .slider .carousel .carousel-inner .carousel-item img {
          width: 100%;
          height: 100%;
          object-fit: cover; }
        .slider .carousel .carousel-inner .carousel-item .text {
          -webkit-touch-callout: none;
          -webkit-user-select: none;
          -khtml-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          width: 100%;
          text-align: center;
          z-index: 10;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          color: #FFFFFF; }
          .slider .carousel .carousel-inner .carousel-item .text h4 {
            color: #FEBA11;
            font-size: 1.5rem;
            line-height: 2rem;
            font-weight: 700; }
          .slider .carousel .carousel-inner .carousel-item .text h2 {
            font-size: 4rem;
            line-height: 5.5rem;
            font-weight: 600; }
          .slider .carousel .carousel-inner .carousel-item .text p {
            font-size: 1.5rem;
            line-height: 2.1rem; }
          .slider .carousel .carousel-inner .carousel-item .text .animateButton {
            background-color: #FEBA11;
            height: 3.5rem;
            border-radius: 35px; }
          .slider .carousel .carousel-inner .carousel-item .text .animacija {
            background-color: #7A1C7A; }
    .slider .carousel .carousel-control-prev {
      opacity: 1;
      z-index: 11; }
      .slider .carousel .carousel-control-prev .carousel-control-prev-icon {
        position: absolute;
        left: 3rem;
        background-image: url("././images/carousel/arrowLeft.svg");
        width: 3rem;
        height: 3rem;
        z-index: -1;
        cursor: pointer; }
    .slider .carousel .carousel-control-next {
      opacity: 1;
      z-index: 11; }
      .slider .carousel .carousel-control-next .carousel-control-next-icon {
        position: absolute;
        right: 3rem;
        background-image: url("././images/carousel/arrowRight.svg");
        width: 3rem;
        height: 3rem;
        z-index: -1;
        cursor: pointer; }

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .slider .carousel .carousel-inner .carousel-item .text h2 {
    font-size: 3rem;
    line-height: 4.5rem;
    font-weight: 600; } }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .slider {
    height: 20rem; }
    .slider .carousel .carousel-inner .carousel-item .text h2 {
      font-size: 1.78rem;
      line-height: 2.2rem; }
    .slider .carousel .carousel-inner .carousel-item .text p {
      font-size: 1rem;
      line-height: 2.1rem; }
    .slider .carousel .carousel-control-prev .carousel-control-prev-icon {
      left: 1rem; }
    .slider .carousel .carousel-control-next .carousel-control-next-icon {
      right: 1rem; } }

.galleryPictures {
  margin-top: 3rem; }
  .galleryPictures img {
    border-radius: 1rem;
    margin-bottom: 1.5rem;
    cursor: pointer;
    transition: transform .3s; }
    .galleryPictures img:hover {
      transform: scale(1.1); }

.lightbox {
  overflow-y: hidden !important;
  overflow-x: hidden !important;
  background-color: #333333;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1213113212; }
  .lightbox .container {
    background: #333333; }
  .lightbox img {
    height: 100vh;
    object-fit: contain;
    margin-top: 40px; }
  .lightbox .fa-times {
    color: white;
    font-size: 42px !important;
    position: absolute;
    top: 60px;
    right: 70px;
    z-index: 2; }
    .lightbox .fa-times:hover {
      cursor: pointer; }
  .lightbox .fa-angle-left {
    color: white;
    font-size: 50px;
    position: fixed;
    top: 48%;
    left: 40px; }
    .lightbox .fa-angle-left:hover {
      cursor: pointer; }
  .lightbox .fa-angle-right {
    color: white;
    font-size: 50px;
    position: fixed;
    top: 48%;
    right: 40px; }
    .lightbox .fa-angle-right:hover {
      cursor: pointer; }

.happening {
  overflow: hidden;
  position: relative;
  padding-top: 4.5rem;
  padding-bottom: 5rem;
  text-align: center; }
  .happening h1 {
    font-size: 3.2rem;
    font-weight: 700;
    line-height: 3.75rem;
    color: #7A1C7A;
    margin-bottom: 1rem; }
  .happening p {
    font-size: 1.1rem;
    line-height: 1.65rem;
    font-weight: 400;
    margin-bottom: 1rem; }
  .happening .animatedBalloon .balloon {
    left: 3.4rem; }
  .happening .animatedBalloon .star {
    left: 6.3rem; }
  .happening .animatedBalloonRight .balloonRight {
    bottom: 5rem;
    right: 6rem; }
  .happening .animatedBalloonRight .starRight {
    right: 3.5rem;
    margin-top: 3rem; }
  .happening p {
    margin-bottom: 2.75rem; }
  .happening .animateButton {
    width: 15rem;
    background-color: #7A1C7A;
    border-radius: 1.75rem; }
  .happening .animacija {
    background-color: #FEBA11; }

.boxHappening {
  transition: transform .3s;
  margin-bottom: 2rem;
  display: flex;
  width: 100%;
  border-radius: 1rem;
  -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  overflow: hidden;
  max-height: 285px;
  text-align: start; }
  .boxHappening .contentHappening {
    width: 50%;
    padding: 1rem 1.6rem 0 1.25rem; }
    .boxHappening .contentHappening a {
      color: #222222; }
      .boxHappening .contentHappening a h5 {
        font-size: 1.3rem;
        line-height: 1.65rem;
        font-weight: 700;
        margin-bottom: 0; }
      .boxHappening .contentHappening a:hover {
        text-decoration: none; }
    .boxHappening .contentHappening h6 {
      font-size: .8rem;
      line-height: 1.4rem;
      font-weight: 600;
      color: #7A1C7A; }
    .boxHappening .contentHappening p {
      line-height: 1.4rem;
      font-size: 1rem;
      height: 6.95rem;
      overflow: hidden;
      word-break: break-all; }
  .boxHappening .image {
    width: 50%; }
    .boxHappening .image img {
      object-fit: cover;
      height: 100%; }
  .boxHappening:hover {
    transform: scale(1.1); }

.happeningPage {
  position: relative;
  margin-top: 1.75rem;
  text-align: start;
  margin-bottom: 5.5rem; }
  .happeningPage h1 {
    font-size: 3.2rem;
    font-weight: 700;
    line-height: 3.75rem;
    color: #7A1C7A;
    margin-bottom: 1rem; }
  .happeningPage p {
    font-size: 1.1rem;
    line-height: 1.65rem;
    font-weight: 400;
    margin-bottom: 1rem; }
  .happeningPage p {
    margin-bottom: 3.5rem; }
  .happeningPage .animatedBalloon .blueBalloon {
    top: 40rem !important; }
  .happeningPage .animatedBalloonRight .starRight {
    top: 15rem; }

.homeOffer {
  z-index: 2;
  background-color: #FEBA11;
  text-align: center;
  padding-top: 4.25rem;
  padding-bottom: 4.8rem; }
  .homeOffer h1 {
    font-size: 3.2rem;
    font-weight: 700;
    line-height: 3.75rem;
    color: #FFFFFF;
    margin-bottom: 2.6rem; }
  .homeOffer .wrapper .wrappBox {
    width: 100%;
    display: flex;
    justify-content: center; }
    .homeOffer .wrapper .wrappBox .box {
      border-radius: 100%;
      overflow: hidden;
      border: 5px solid #FFFFFF;
      height: 12.75rem;
      width: 12.75rem; }
      .homeOffer .wrapper .wrappBox .box img {
        object-fit: cover;
        width: 100%;
        height: 100%; }
  .homeOffer .wrapper h3 {
    margin-top: 1rem;
    font-size: 1.5rem;
    color: #FFFFFF;
    line-height: 2rem;
    font-weight: 700; }
  .homeOffer .wrapper p {
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 1.1rem;
    line-height: 1.65rem; }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .homeOffer .wrapper .wrappBox .box {
    height: 19rem;
    width: 100%; } }

@media screen and (max-width: 1023px) and (min-width: 768px) {
  .homeOffer .wrapper .wrappBox {
    padding: 0; } }

.welcomeSection {
  position: relative;
  margin-top: 4.5rem;
  margin-bottom: 3rem; }
  .welcomeSection h1 {
    font-size: 3.2rem;
    font-weight: 700;
    line-height: 3.75rem;
    color: #7A1C7A;
    margin-bottom: 1rem; }
  .welcomeSection p {
    font-size: 1.1rem;
    line-height: 1.65rem;
    font-weight: 400;
    margin-bottom: 1rem; }
  .welcomeSection .animateButton {
    background: #7A1C7A;
    border-radius: 1.75rem;
    border: 0 !important;
    outline: none !important;
    overflow: hidden;
    position: relative;
    width: 10rem;
    height: 3.5rem;
    line-height: 0.8rem;
    box-shadow: none;
    margin-top: 1rem; }
    .welcomeSection .animateButton span {
      font-size: 1rem;
      line-height: 1.2rem;
      position: relative;
      color: #FFFFFF;
      z-index: 2;
      letter-spacing: 0;
      font-weight: 600; }
    .welcomeSection .animateButton:focus span, .welcomeSection .animateButton:hover span {
      color: #FFFFFF; }
  .welcomeSection .animacija {
    opacity: 1;
    border-radius: 1.25rem;
    background: #FEBA11;
    animation-name: buttonAnimacija;
    animation-duration: 0.7s;
    width: 800px;
    height: 800px;
    margin-left: -420px;
    margin-top: -450px;
    z-index: 4; }

@keyframes buttonAnimacija {
  from {
    width: 0px;
    height: 0px;
    border-radius: 100%;
    margin-left: 0;
    margin-top: 0; }
  to {
    width: 800px;
    height: 800px;
    margin-left: -420px;
    margin-top: -450px;
    border-radius: 100%; } }
  .welcomeSection .animatedBalloon {
    top: 2.25rem; }
  .welcomeSection .lines .linesLeft {
    z-index: -1; }
  .welcomeSection .lines .rinesRight {
    z-index: -1; }

@media screen and (min-width: 1600px) {
  .welcomeSection .img {
    width: calc(100% + 60px) !important;
    max-width: unset;
    object-fit: contain; } }

@media screen and (max-width: 767px) and (min-width: 0) {
  .welcomeSection {
    margin: 3rem 0; }
    .welcomeSection .img {
      margin-top: 3rem; } }

.animatedBalloon {
  position: absolute;
  left: 1.85rem;
  top: 9rem; }
  .animatedBalloon .balloon {
    position: absolute;
    animation: shake 4s;
    animation-iteration-count: infinite;
    pointer-events: none; }
  .animatedBalloon .star {
    animation: shake2 4s;
    animation-iteration-count: infinite;
    position: absolute;
    left: 7.4rem;
    top: 20.9rem;
    pointer-events: none; }

.animatedBalloonRight {
  right: 10rem; }
  .animatedBalloonRight .balloonRight {
    position: absolute;
    animation: shake 4s;
    animation-iteration-count: infinite;
    bottom: -5rem;
    right: 5rem;
    z-index: -1;
    pointer-events: none; }
  .animatedBalloonRight .starRight {
    animation: shake2 4s;
    animation-iteration-count: infinite;
    position: absolute;
    right: 5rem;
    pointer-events: none; }

@keyframes shake {
  0% {
    transform: translate(0px, 0px) rotate(0deg); }
  10% {
    transform: translate(1px, 3px) rotate(-1deg); }
  20% {
    transform: translate(-4px, 0px) rotate(1deg); }
  30% {
    transform: translate(5px, 3px) rotate(0deg); }
  40% {
    transform: translate(1px, -1px) rotate(1deg); }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg); }
  60% {
    transform: translate(-3px, 1px) rotate(0deg); }
  70% {
    transform: translate(3px, 1px) rotate(-1deg); }
  80% {
    transform: translate(-1px, -1px) rotate(1deg); }
  90% {
    transform: translate(1px, 2px) rotate(0deg); }
  100% {
    transform: translate(1px, -2px) rotate(-1deg); } }

@keyframes shake2 {
  0% {
    transform: translate(0px, 0px) rotate(0deg); }
  10% {
    transform: translate(1px, 2px) rotate(-1deg); }
  20% {
    transform: translate(-3px, 0px) rotate(1deg); }
  30% {
    transform: translate(3px, 2px) rotate(0deg); }
  40% {
    transform: translate(1px, -1px) rotate(1deg); }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg); }
  60% {
    transform: translate(-3px, 1px) rotate(0deg); }
  70% {
    transform: translate(3px, 1px) rotate(-1deg); }
  80% {
    transform: translate(-1px, -1px) rotate(1deg); }
  90% {
    transform: translate(1px, 2px) rotate(0deg); }
  100% {
    transform: translate(1px, -2px) rotate(-1deg); } }

.lines .linesLeft {
  position: absolute;
  left: 1.5rem;
  z-index: 1;
  top: -8rem;
  animation: shake2 4s;
  animation-iteration-count: infinite; }

.lines .rinesRight {
  position: absolute;
  right: 1.75rem;
  z-index: 1;
  animation: shake2 4s;
  animation-iteration-count: infinite; }

@media screen and (max-width: 1679px) and (min-width: 0px) {
  .animatedBalloon,
  .animatedBalloonRight,
  .vrAnim,
  .lines,
  .vrAnim {
    display: none; } }
